import * as React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <div>{element}</div>
      {process.env.NODE_ENV === "production" && (
        <Script
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "3407703f01d24505af7187da67e7ae96"}'
        ></Script>
      )}
    </>
  )
}
