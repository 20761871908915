module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ride Skills Home Page","short_name":"Ride Skills","start_url":"/","background_color":"#124749","theme_color":"#124749","icon":"src/favicon.png","icons":[{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicons/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/apple-touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/favicons/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d43831e7c807e1297f45740960f4d0a"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
